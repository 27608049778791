<!-- 考生统计表详情 -->
<template>
    <div class="ExamineeDetails">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">考生统计表详情</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 查询表单 -->
        <el-form ref="form" :model="form" inline class="form" id="form" label-width="100px">
            <el-form-item label="考试日期">
                <el-date-picker v-model="form.dateQuery" type="daterange"
                range-separator="至" start-placeholder="开始日期" value-format="yyyy-MM-dd"
                    end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <!-- <el-form-item label="机构名称">
                <el-select v-model="form.mechanism" filterable placeholder="请输入并选择机构" :clearable="true">
                    <el-option v-for="item in form.mechanismOpt" :key="item.id"
                         :label="item.name" :value="item.id">
                     </el-option>
                 </el-select>
            </el-form-item> -->
            <!-- <el-form-item label="选择地区">
                <el-select v-model="form.region"  placeholder="请选择地区" :clearable="true">
                    <el-option v-for="item in form.regionOpt" :key="item.value"
                         :label="item.label" :value="item.label">
                     </el-option>
                 </el-select>
            </el-form-item> -->
            <!-- <el-form-item label="搜索">
                <el-input v-model="form.search" placeholder="请输入关键词"></el-input>
            </el-form-item> -->
        </el-form>
        
        <div class="" style="display: flex;justify-content: space-between;">
            <div class="" style="margin-left:22px ;">
                <el-button size="small" icon="el-icon-refresh" style="background-color: #2C3E50; border-radius: 3px;"
                    @click="refresh">
                    <!-- 刷新 -->
                </el-button>
                <button class="derive" @click="derive">导出到Excel</button>
                
            </div>
            <div class="" style="margin-right: 10px;text-align: right;">
                <el-button size="small" style="background: #18BC9C;color: #FFFFFF;" @click="query">
                    查询
                </el-button>
                <el-button size="small" style="background: #FAFAFA;color: #666666;" @click="Reset">
                    重置
                </el-button>
            </div>
        </div>     
        
        <!-- 表格 -->
        <el-table :data="tableData" border stripe style="width:97.9%;flex: 1;" height="650" id="out-table">
            <el-table-column type="index" label="序号" align="center" width="80">
            </el-table-column>
            <el-table-column prop="status" label="状态" align="center" width="150">
                <template slot-scope="scope">
                    <span v-if="scope.row.status==0||scope.row.status==''"></span>
                    <span v-else-if="scope.row.status==1">草稿</span>
                    <span v-else-if="scope.row.status==2">报考</span>
                    <span v-else-if="scope.row.status==3">准考</span>
                    <span v-else-if="scope.row.status==4">考试OK</span>
                    <span v-else-if="scope.row.status==5">取消</span>
                    <span v-else-if="scope.row.status==6">退款取消</span>
                    <span v-else-if="scope.row.status==7">延期</span>
                    <span v-else="scope.row.status==8">缺考</span>
                </template>
            </el-table-column>
            <el-table-column prop="certificate_no" label="准考证" align="center" width="200">
            </el-table-column>
            <el-table-column prop="name" label="学员姓名" align="center" width="150">
            </el-table-column>
            <el-table-column prop="english_name" label="拼音名" align="center" width="150">
            </el-table-column>
            <el-table-column prop="exam_date" label="报名日期" align="center" width="150">
            </el-table-column>
            <el-table-column prop="exam2_date" label="考试日期" align="center" width="150">
            </el-table-column>
            <el-table-column prop="exam_startdate" label="开始时间" align="center" width="150">
            </el-table-column>
            <el-table-column prop="price1" label="应收金额" align="center" width="120">
            </el-table-column>
            <el-table-column prop="course" label="报考科目" align="center" width="120">
            </el-table-column>
            <el-table-column prop="exam_level" label="级别" align="center" width="100">
            </el-table-column>
            <el-table-column prop="exam_type" label="考试类型" align="center" width="120">
            </el-table-column>
            <el-table-column prop="address" label="所在考场" align="center" width="300" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="phone" label="移动电话" align="center" width="150">
            </el-table-column>
            <el-table-column prop="enroll_price" label="报名金额(元)" align="center" width="200">
            </el-table-column>
            <el-table-column  label="操作" align="center" width="100" fixed="right">
                <template slot-scope="scope">
                    <el-button class="more" icon="el-icon-view" size="mini" 
                    @click="detailed(scope.$index, scope.row)">
                        详情
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        
        <!-- 详情弹窗 -->
        <el-dialog  :center="true" :show-close="false" width="1051px" :visible.sync="dialogTableVisible">
            <div class="" style="text-align: right;">
                <el-button class="el-btn" size="small" @click="dialogTableVisible=false">
                    返回
                </el-button>
            </div>
            <div class="" style="color: #333;font-size: 20px;font-weight: bold;text-align: center;">
                考生报名明细
            </div>
          <el-table :data="gridData" border stripe class="table" height="300">
            <el-table-column prop="name" label="姓名" align="center" width="120">
            </el-table-column>
            <el-table-column prop="exam_date" label="报名时间" align="center" width="150">
            </el-table-column>
            <el-table-column prop="exam2_date" label="考试时间" align="center" width="150">
            </el-table-column>
            <el-table-column prop="exam_startdate" label="开始时间" align="center" width="150">
            </el-table-column>
            <el-table-column prop="course" label="报考科目" align="center" width="150">
            </el-table-column>
            <el-table-column prop="org_number" label="机构编码" align="center" width="150">
            </el-table-column>
            <el-table-column prop="org_name" label="机构名称" align="center" width="150">
            </el-table-column>
            <el-table-column prop="exam_level" label="等级" align="center" width="100">
            </el-table-column>
            <el-table-column prop="price1" label="应收金额" align="center" width="150">
            </el-table-column>
          </el-table>
        </el-dialog>
        
        <!-- 底部 -->
        <div class="" style="display: flex;justify-content: space-between;">
            <div class="" style="margin-left: 22px;line-height: 50px;">
                 报名金额汇总：<input type="text" class="itp" :value="summary" disabled="disabled"/>
            </div>
            <div class="" style="margin-right:22px;">
                <!--分页 -->
                <el-pagination @size-change="handleSizeChange" 
                @current-change="handleCurrentChange" :current-page="currentPage"
                    :page-sizes="[20,50,100,200,500,1000]" :page-size="10" background
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                form:{
                    dateQuery:[],//考试日期
                    mechanism:"",//机构名称id
                    mechanismOpt:[],//机构选项
                    region:"",//选择地区
                    regionOpt:[
                        {label:"广东省",value:0},
                        {label:"龙岗区",value:1},
                        {label:"龙华区",value:2},
                        {label:"福田区",value:3},
                        {label:"罗湖区",value:4},
                        {label:"南山区",value:5},
                        {label:"宝安区",value:6},
                        {label:"光明区",value:7},
                        {label:"盐田区",value:8},
                        {label:"坪山区",value:9},
                        {label:"大鹏新区",value:10},
                        {label:"深汕合作区",value:11},
                        {label:"港澳台地区",value:12},
                        {label:"其他",value:13}
                    ],//地区选项
                    search:""//搜索
                },
                currentPage: 1,
                currentLimit: 20, //条数
                total: 0,
                tableData:[],
                summary:"",//报名金额汇总
                gridData:[],
                dialogTableVisible: false,
            }
        },
        created() {
            // 选择机构
            // this.$request({
            //     url: "/api/mechanism/list",
            //     method: "POST",
            //     data: {
            //         page: 1,
            //         limit: 1000
            //     }
            // }).then(res => {
            //     // console.log("选择机构",res.data.list)
            //     this.form.mechanismOpt = res.data.list
            // })
            this.form.dateQuery=this.$route.query.dateQuery
            this.getList()
        },
        methods:{
            getList(){
                this.$request({
                    url:'/api/Statistics/detail1',
                    method:'POST',
                    data:{
                        limit:this.currentLimit,
                        page: this.currentPage,
                        dateQuery:this.form.dateQuery,//查询时间段
                        org_id:this.$route.query.org_id//机构id
                    }
                }).then(res=>{
                    if(res.code==1){
                        console.log(res,"列表数据")
                        this.tableData=res.data.list
                        this.total=res.data.count
                        this.summary=res.data.enroll_price
                    }
                })
            },
            handleSizeChange(val) {
                this.currentLimit=val
                this.getList()
                // console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.currentPage=val
                this.getList()
                // console.log(`当前页: ${val}`);
            },
            goBack() { //返回
                this.$router.go(-1)
            }, 
            // 刷新
            refresh() {
                this.$router.push({
                    path: "/home/newpage", //空页面路由
                    query: {
                        path: this.$route.path
                    }
                })
            },
            detailed(index,row){//详情
                console.log(row)
                this.dialogTableVisible=true
                this.$request({
                    url:'/api/Statistics/detail2',
                    method:'POST',
                    data:{
                        signup_id:row.id//报考id
                    }
                }).then(res=>{
                    console.log(res,"详情")
                    this.gridData=res.data.list
                })
            },
            query() { //查询
                this.currentPage = 1
                this.getList()
            },
            Reset() { //重置
                this.form.dateQuery = [] //考试日期
                this.form.mechanism = '' //机构名称
                this.form.region = '' //选择地区
                this.form.search = ''
                this.getList()
            },
            derive(){//导出
                location.href = `https://artxt.szart.cn/api/public/index.php/api/Statistics/detail1?
                page=${this.currentPage}&limit=${this.currentLimit}&dateQuery=${this.form.dateQuery}&org_id=${this.$route.query.org_id}`
            }
        }
    }
</script>

<style scoped="scoped">
    .ExamineeDetails{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 39px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 查询部分 */
    .form {
        /* padding-top: 34px; */
    }
    
    .form ::v-deep .el-input__inner {
        width: 276px;
        height: 37px;
    }
    
    .ExamineeDetails ::v-deep .el-form-item__label {
        color: #000000;
    }
    /* 按钮 */
    .ExamineeDetails ::v-deep .el-icon-refresh {
        font-weight: bold !important;
        color: #FFFFFF;
    }
    .form {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    /* 表格 */
    .el-table {
        margin: 20px;
    }
    
     ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 29px;
    }
    .more{
       background: #18BC9C; 
       color: #fff;
    }
    .itp{
        padding-left: 5px;
        border: 1px solid #ccc;
        height: 25px;
        outline: none;
        font-size: 14px;
        background-color: #FFFFFF;
    }
    /* 导出按钮 */
    .derive {
        display: inline-block;
        width: 113px;
        height: 32px;
        padding-left: 10px;
        vertical-align: middle;
        background: url(../../assets/export.png) 8px 8px no-repeat #FF7300;
        color: #FFFFFF;
        border-radius: 3px;
        border: none;
        margin-left: 10px;
        cursor: pointer;
        outline: none;
    }
    /* 弹出框 */
    .ExamineeDetails ::v-deep .el-dialog__header{
        padding: 0px !important;
    }
    .ExamineeDetails ::v-deep .el-dialog__body {
        padding: 25px 25px 30px !important;
    }
    .table{
        margin: 10px;
    }
    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active{
        background-color: #2C3E50 !important;
    }
</style>
